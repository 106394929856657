/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
@import "@ionic/angular/css/palettes/dark.system.css";


.auto-size-modal {
    --width: 400px; /* Largura automática */
    --height: 510px; /* Altura automática */
    --min-width: 300px; /* Largura mínima para evitar que fique muito pequeno */
    --min-height: 400px; /* Altura mínima para evitar que fique muito pequeno */
    --border-radius: 8px; /* Borda arredondada */
    --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Sombra */
  
    ion-content {
      --padding-top: 16px;
      --padding-bottom: 16px;
      --padding-start: 16px;
      --padding-end: 16px;
    }
  
    ion-datetime {
      width: 100%; /* Ocupa toda a largura disponível */
      min-width: 280px; /* Largura mínima para o ion-datetime */
      height: auto; /* Altura automática */
    }
  
    ion-footer {
      padding: 16px;
      box-shadow: none !important;
    }

}

